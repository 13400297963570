import axios from 'axios';
import { baseUrl } from './envhttp';
import { getErpId, getUserId } from './storage';

const erp_id = getErpId();
const user_id = getUserId();
axios.defaults.baseURL =baseUrl;

export const listApi =(title)=>{
    return axios.get(title,{ params: { erp_id } })
}

export const getDataApi =(title)=>{
    return axios.get(title,{ params: { erp_id } })
}

export const saveApi = (inputs,pUrl)=>{
    // let data  = {email:inputs.email,password:inputs.password }
     return axios.post(pUrl,inputs);
 }

 export const saveMainApi = (state,data,pUrl)=>{
    // let data  = {email:inputs.email,password:inputs.password }
     return axios.post(pUrl,{ state, data });
 }

 export const getSingleDataApi =(title,id)=>{
    return axios.get(title,{ params: {id, erp_id } })
}

export const deleteSingleApi = (pUrl,id)=>{
     return axios.post(pUrl,null, {params:{id,erp_id,user_id}});
 }

 export const deleteSingleMainApi = (pUrl,detail_id,id)=>{
    return axios.post(pUrl,null, {params:{detail_id,id,erp_id,user_id}});
}

 export const updateMainApi = (pUrl,id)=>{
    return axios.post(pUrl,null, {params:{id,erp_id,user_id}});
}

export const updateInvMainApi = (state,data,pUrl,id)=>{
    return axios.post(pUrl,{ state, data }, {params:{id,erp_id,user_id}});
}