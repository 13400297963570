

export const storeUserData = (data)=>{
    console.log(data);
	localStorage.setItem("user_id",data[1][0].id);
    localStorage.setItem("erp_id",data[0][0].id);
	localStorage.setItem("pic",data[0][0].pic);
    localStorage.setItem("erp_txn_type",data[0][0].erp_txn_type);
	localStorage.setItem("erp_unit",data[0][0].erp_unit);
	localStorage.setItem("erp_currency",data[0][0].erp_currency);
	localStorage.setItem("erp_sales_setting",data[0][0].erp_sales_setting);
	localStorage.setItem("erp_type",data[0][0].erp_type);
	localStorage.setItem("erp_title",data[0][0].erp_title);
    localStorage.setItem("erp_contact",data[0][0].erp_contact);
    localStorage.setItem("erp_address",data[0][0].erp_address);
    localStorage.setItem("gst_number",data[0][0].gst_number);
	localStorage.setItem("user_access",data[1][0].user_access);
}

export const getErpId = ()=>{
    return localStorage.getItem('erp_id');
}

export const getUserId = ()=>{
    return localStorage.getItem('user_id');
}

export const getErpPic = ()=>{
    return localStorage.getItem('pic');
}

export const getTxnType = ()=>{
    return localStorage.getItem('erp_txn_type');
}

export const getUnit = ()=>{
    return localStorage.getItem('erp_unit');
}

export const getCurrency = ()=>{
    return localStorage.getItem('erp_currency');
}

export const getSalesSettings = ()=>{
    return localStorage.getItem('erp_sales_setting');
}

export const getErpType = ()=>{
    return localStorage.getItem('erp_type');
}

export const getErpTitle = ()=>{
    return localStorage.getItem('erp_title');
}

export const getErpContact = ()=>{
    return localStorage.getItem('erp_contact');
}

export const getErpAddress = ()=>{
    return localStorage.getItem('erp_address');
}

export const getErpGst= ()=>{
    return localStorage.getItem('gst_number');
}

export const getUserAccess = ()=>{
    return localStorage.getItem('user_access');
}

export const removeUserData = ()=>{
     localStorage.removeItem('erp_id');
     localStorage.removeItem('user_id');
     localStorage.removeItem('pic');
     localStorage.removeItem('erp_txn_type');
     localStorage.removeItem('erp_unit');
     localStorage.removeItem('erp_currency');
     localStorage.removeItem('erp_sales_setting');
     localStorage.removeItem('erp_type');
     localStorage.removeItem('erp_title');
     localStorage.removeItem('user_access');
     localStorage.removeItem('erp_contact');
     localStorage.removeItem('erp_address');
     localStorage.removeItem('gst_number');
}