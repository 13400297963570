import TableList from '../components/TableList';
import '../scss/dashboard.scss';
import FooterPage from '../Layouts/FooterPage';
import { useEffect, useState } from 'react';
import { getDataApi } from '../services/appApi';

function DashPage() {
   const [txn, setTxn] = useState([]);
  
   useEffect(() => {
      const fetchWidget = async () => {
        try {
          let res = await getDataApi("home");
          console.log("wel")
          console.log(res.data);
          setTxn(await res.data);
        } catch (err) {
          console.log(err);
        }
      }
      fetchWidget();
    }, []);

  return (
    <div className='dashboard'>
      <div className='dashboardContainer'> 
     <div className="listContainer">
      <div className="listTitle">Transations</div>
      <TableList dashboard_txn={txn} />
     </div>
     <FooterPage/>
      </div>
   </div>
  )
}

export default DashPage