import '../scss/table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getCurrency } from '../services/storage';


function TableList({dashboard_txn}) {

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className='tableCellHead'>Txn Id</TableCell>
            <TableCell className='tableCellHead'>Tracking Id</TableCell>
            <TableCell className='tableCellHead'>Date</TableCell>
            <TableCell className='tableCellHead'>Transaction Type</TableCell>
            <TableCell className='tableCellHead'>Amount({getCurrency()})</TableCell>
            <TableCell className='tableCellHead'>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dashboard_txn.map((row) => (
            <TableRow key={row.id}>
              <TableCell  className='tableCell'>
                {row.id}
              </TableCell>
              <TableCell className='tableCell'>{row.txn_type_id}</TableCell>
              <TableCell className='tableCell'>{row.creation_date}</TableCell>
              <TableCell className='tableCell'>{row.txn_type}</TableCell>
              <TableCell className='tableCell'>{row.txn_amount}</TableCell>
              <TableCell className='tableCell'>
                <div className={`status ${row.actions}`}>{row.actions}</div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableList