import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashPage from './pages/DashPage';
import "./style/dark.scss";
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';

function App() {
  //const[dark,setDark]=useState(false);
  const{darkMode}=useContext(DarkModeContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
    <Router>
      <Routes>
           <Route path="/">
             <Route index  element={<DashPage/>}/>
          </Route>
       </Routes>
    </Router>
  </div>
  );
}

export default App;
